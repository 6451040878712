import { Role } from '../../data/models';

export enum Permission {
	EDIT_SETTINGS = 'EDIT_SETTINGS',
	EDIT_PLANNING = 'EDIT_PLANNING',
	EDIT_MODEL = 'EDIT_MODEL',
	EDIT_MARKETING = 'EDIT_MARKETING',
	ALL_ANALYTICS = 'ALL_ANALYTICS',
	CHANGE_TENANT = 'CHANGE_TENANT',
	CREATE_TENANT = 'CREATE_TENANT',
}

export const SUPER_ADMIN_ROLE = 'SUPER_ADMIN';
export const VIEWER_ROLE = 'VIEWER';

export class PermissionManager {
	private static permissions: Permission[] = [];
	private static analyticsSheets: string[] = [];

	static setPermissions(role: Role) {
		PermissionManager.permissions = role.permissions;
		PermissionManager.analyticsSheets = role.analyticsSheets;
	}

	static hasPermission(permission: Permission): boolean {
		return PermissionManager.permissions.includes(permission);
	}

	static hasPermissionForSheet(sheetId: string) {
		return (
			PermissionManager.hasPermission(Permission.ALL_ANALYTICS) || PermissionManager.analyticsSheets.includes(sheetId)
		);
	}
}
