import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';

import deepEqual from 'deep-equal';
import { combineLatest, distinctUntilChanged, from, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { QlikService } from '@app/shared/core';
import { WebStorage } from '@app/shared/core/services/webStorage';
import {
	AnalyticsAuthenticationStatus, AnalyticsSheetWithId, BookingsTarget, CustomScorecardDAO, DaoFactory, defaultSheets,
	InitiativeDAO, Path, PersistenceService, ProcessDAO, RevenueStreamDAO, Scorecard, Strategy, StrategyDAO,
	strategyStartDate, StreamData, StreamDataDAO,
} from '@app/shared/data';
import { LocalStorageKeys } from '@app/shared/shared/constants';

import { KpiBox } from '../components/kpi-box/kpi-box.component';
import { PermissionManager } from '../utils';
import { TenantService } from './tenant.service';

export interface StrategyRevenueStream {
	name: string;
	description?: string;
	bookingsTarget: BookingsTarget;
	data: StreamDataDAO;
}

@Injectable({
	providedIn: 'root',
})
export class StrategyService {
	selectedStrategy: Observable<StrategyDAO>;
	private _year = new ReplaySubject<number>(1);

	constructor(
		private tenantService: TenantService,
		private persistenceService: PersistenceService,
		private daoFactory: DaoFactory,
		private qlikService: QlikService
	) {
		this.selectedStrategy = this.getSelectedStrategy();
		void tenantService.determineStartingStrategyYear().then((year) => this.selectStrategy(year));
	}

	static get selectedStrategyYear(): number {
		return WebStorage.getItem(LocalStorageKeys.SelectedStrategyYear);
	}

	selectStrategy(year: number) {
		WebStorage.setItem(LocalStorageKeys.SelectedStrategyYear, year);
		this._year.next(year);
	}

	getActiveProcesses(): Observable<ProcessDAO[]> {
		return this.selectedStrategy.pipe(
			switchMap((x) => x.processes),
			map((ps) =>
				ps
					.filter((p) => p.snapshot.isActive !== false)
					.sort((a, b) => (a.snapshot.name ?? '').localeCompare(b.snapshot.name ?? ''))
			)
		);
	}

	getCurrentRevenueStreams(): Observable<RevenueStreamDAO[]> {
		return this.selectedStrategy.pipe(
			switchMap((s) => this.tenantService.getRevenueStreams(strategyStartDate(s.snapshot))),
			shareReplay(1)
		);
	}

	initRevenueStreams(): Observable<StrategyRevenueStream[]> {
		return combineLatest([this.selectedStrategy, this.getCurrentRevenueStreams()]).pipe(
			switchMap(([stratDao, streamDaos]) => {
				if (!streamDaos.length) return of([] as StrategyRevenueStream[]);
				return combineLatest(
					streamDaos.map((y) =>
						combineLatest([
							y.stream,
							this.daoFactory.build<StreamData, StreamDataDAO>(
								StreamDataDAO,
								Path.streamData(TenantService.tenantId, stratDao.id, y.id)
							),
						]).pipe(
							switchMap(async ([stream, dataDao]) => {
								if (dataDao.snapshot == null) await this.persistenceService.set(dataDao.path, {});
								return {
									name: stream.name,
									description: stream.description,
									bookingsTarget: stream.bookingsTargets?.[stratDao.snapshot.year] ?? ({} as BookingsTarget),
									data: dataDao,
								} as StrategyRevenueStream;
							})
						)
					)
				);
			}),
			map((streams) => streams.filter((x) => x.bookingsTarget.amount != null && x.bookingsTarget.yoyGrowth != null)),
			shareReplay(1)
		);
	}

	getStrategyAnalyticsSheets(): Observable<AnalyticsSheetWithId[]> {
		return this.selectedStrategy.pipe(
			map((s) => s.snapshot.analyticsSheets ?? {}),
			distinctUntilChanged((a, b) => deepEqual(a, b)),
			map((analyticsSheets) => {
				const base = { ...defaultSheets };
				const custom = Object.entries(analyticsSheets).filter(([, v]) => v.order != null && v.url);
				for (const [k, v] of Object.entries(base)) {
					v.url = (analyticsSheets ?? {})[k]?.url;
				}
				const sheets: AnalyticsSheetWithId[] = Object.entries(base)
					.concat(custom)
					.map(([k, v]) => ({ id: k, ...v }))
					.filter((x) => PermissionManager.hasPermissionForSheet(x.id));
				sheets.sort((a, b) => a.order - b.order);
				return sheets;
			})
		);
	}

	getAnalyticsGraph(): Observable<string | AnalyticsAuthenticationStatus.Unauthenticated> {
		return this.selectedStrategy.pipe(
			switchMap((strategy) => this.qlikService.fetch(strategy.snapshot.analyticsGraph))
		);
	}

	private getSelectedStrategy(): Observable<StrategyDAO> {
		return this._year.pipe(
			switchMap((year) => this.getOrCreateStrategybyYear(year)),
			shareReplay(1)
		);
	}

	private getOrCreateStrategybyYear(year: number): Observable<StrategyDAO> {
		return this.tenantService.getTenant().pipe(
			switchMap((t) => t.strategies),
			switchMap((strategies: StrategyDAO[]) => {
				const matchingStrategy = strategies.find((strategy) => strategy.snapshot.year === year);
				if (matchingStrategy) return of(matchingStrategy);
				const lastYear = strategies.find((strategy) => strategy.snapshot.year === year - 1);
				return from(this.createStrategy(year, lastYear.snapshot, Path.tenant(TenantService.tenantId))).pipe(
					switchMap((newStrategyRef) => this.daoFactory.build<Strategy, StrategyDAO>(StrategyDAO, newStrategyRef.path))
				);
			})
		);
	}

	private async createStrategy(year: number, previousYear: Strategy, path: string): Promise<DocumentReference> {
		return this.persistenceService.add(`${path}/strategies`, {
			year,
			startingQuarter: previousYear.startingQuarter ?? undefined,
			startingMonth: previousYear.startingMonth ?? undefined,
		});
	}

	getKpiBoxes(): Observable<KpiBox[]> {
		return this.selectedStrategy.pipe(
			switchMap((x: StrategyDAO) =>
				combineLatest([
					x.initiatives.pipe(
						switchMap((i) => (i.length ? combineLatest(i.map((y) => y.stream)) : of([] as Scorecard[]))),
						map((results) => results.sort((s1, s2) => s1.order - s2.order))
					),
					x.customScorecards.pipe(
						switchMap((c) => (c.length ? combineLatest(c.map((y) => y.stream)) : of([] as Scorecard[]))),
						map((results) => results.sort((s1, s2) => s1.order - s2.order))
					),
				])
			),
			map(([a, b]) => [
				...a.map((aa) => ({ ...aa, isInitiative: true }) as KpiBox),
				...b.map((bb) => ({ ...bb, isInitiative: false }) as KpiBox),
			])
		);
	}

	getScorecards(): Observable<(InitiativeDAO | CustomScorecardDAO)[]> {
		return this.selectedStrategy.pipe(
			switchMap((x: StrategyDAO) => combineLatest([x.initiatives, x.customScorecards])),
			map(([a, b]) => [...a, ...b]),
			map((results) => results.sort((s1, s2) => s1.snapshot.order - s2.snapshot.order))
		);
	}
}
