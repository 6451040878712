import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';

import { Entity } from '@app/features/execution/services';
import { CrmFieldSource, RefreshType } from '@app/shared/data';
import { CampaignResponseType } from '@app/shared/data/enums/campaign-response.enum';
import { LocalStorageKeys } from '@app/shared/shared/constants';

import { Field, FieldOption, ReferenceField } from '../models/crm-field.model';
import { LogService } from './logging';
import { WebStorage } from './webStorage';

type FunctionsResponse<T> = T | FunctionsHttpErrorModel;

interface ErrorCode {
	canonicalCode: number;
	status: number;
}

interface FunctionsHttpErrorModel {
	code: firebase.functions.FunctionsErrorCode;
	details?: unknown;
	httpErrorCode: ErrorCode;
}

class FunctionsHttpError extends Error implements FunctionsHttpErrorModel {
	code: firebase.functions.FunctionsErrorCode;
	details?: unknown;
	httpErrorCode: ErrorCode;

	constructor(httpError: FunctionsHttpErrorModel) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		super(httpError.details?.['message'] ?? '');
		this.code = httpError.code;
		this.details = httpError.details;
		this.httpErrorCode = httpError.httpErrorCode;
	}
}

@Injectable({ providedIn: 'root' })
export class FunctionsService {
	constructor(
		private aff: AngularFireFunctions,
		private log: LogService
	) {}

	async callFunction<Ti, To>(name: string, data: Ti, options?: firebase.functions.HttpsCallableOptions): Promise<To> {
		const callable = this.aff.httpsCallable<Ti, FunctionsResponse<To>>(name, options);
		let response: FunctionsResponse<To>;
		try {
			response = await firstValueFrom(callable(data));
			if (isError(response)) {
				throw new FunctionsHttpError(response);
			}
		} catch (err) {
			this.log.error(`Failed to execute ${name}`, {}, err as Error);
			throw err;
		}
		return response;
	}

	async getQlikJwt(): Promise<string> {
		try {
			return await this.callFunction('getQlikJwt', {});
		} catch {
			return '';
		}
	}

	refreshQlikData(hasPipelineInRefresh: boolean, type: RefreshType): Promise<void> {
		const tenantId = WebStorage.getItem(LocalStorageKeys.TenantId);
		return this.callFunction(
			hasPipelineInRefresh ? 'triggerPipeline' : 'triggerQlikRefresh',
			{ tenantId, type },
			{ timeout: 5 * 60 * 1000 }
		);
	}

	async getCrmFields(tenantId: string, type: CrmFieldSource): Promise<(Field | ReferenceField)[]> {
		return this.callFunction('getCrmFields', { tenantId, type });
	}

	async getCrmFieldOptions(tenantId: string, table: string): Promise<FieldOption[]> {
		return this.callFunction('getCrmFieldOptions', { tenantId, table });
	}

	async getMaEntities(tenantId: string, type: CampaignResponseType): Promise<Entity[]> {
		return this.callFunction('getMaEntities', { tenantId, type });
	}

	async getCustomAttributions(tenantId: string, type: CampaignResponseType, typeId: string): Promise<Entity[]> {
		return this.callFunction('getCustomAttributions', { tenantId, type, typeId });
	}

	async sendWelcomeMessage(uid: string): Promise<void> {
		await this.callFunction('sendWelcomeMessage', { uid });
	}

	async getUserIdByEmail(email: string): Promise<string> {
		return this.callFunction('getUserIdByEmail', { email });
	}
}

function isError<T>(resp: FunctionsResponse<T>): resp is FunctionsHttpErrorModel {
	return (
		(resp as FunctionsHttpErrorModel)?.code !== undefined &&
		(resp as FunctionsHttpErrorModel)?.httpErrorCode !== undefined
	);
}
