import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { firstValueFrom } from 'rxjs';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';

export type OkDialogData = OkTextDialogData | OkHtmlDialogData;

interface OkTextDialogData {
	text: string;
	html?: undefined;
	okText?: string;
}

interface OkHtmlDialogData {
	text?: undefined;
	html: string;
	okText?: string;
}

@Component({
	selector: 'ayeq-ok-dialog',
	template: `
		<div mat-dialog-content>
			<p *ngIf="data.text">{{ data.text }}</p>
			<div *ngIf="data.html" [innerHtml]="data.html"></div>
		</div>
		<div mat-dialog-actions align="end">
			<button mat-raised-button color="primary" (click)="close()">
				{{ data.okText || 'OK' }}
			</button>
		</div>
	`,
})
export class OkDialogComponent {
	times = faTimes;
	constructor(
		public dialogRef: MatDialogRef<OkDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: OkDialogData
	) {}

	close(): void {
		this.dialogRef.close();
	}
}

export function openOkDialog(dialog: MatDialog, data: OkDialogData, config?: MatDialogConfig): Promise<void> {
	return firstValueFrom(
		dialog
			.open<OkDialogComponent, OkDialogData>(OkDialogComponent, {
				width: '350px',
				autoFocus: false,
				data,
				...(config ?? {}),
			})
			.beforeClosed()
	);
}
