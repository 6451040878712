// If any of the arrays are empty, we return no values - this is what we want
export function cartesian<T>(arrayOfArrays: T[][]) {
	if (!arrayOfArrays.length) return [];
	if (arrayOfArrays.length === 1) {
		return arrayOfArrays.flat().map((x) => [x]); // Flat would make it an array of strings, we still want an array of arrays
	}
	// https://stackoverflow.com/a/43053803
	return arrayOfArrays.reduce<T[][]>((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat() as T[])), [[]]);
}

export function hasTupleOverlap<T>(idToTuplesMap: Map<T, string[][]>, idToCheck: T): boolean {
	let ownTuples: string[][] = [];
	const otherTuples: string[][] = [];
	for (const [id, tuples] of idToTuplesMap.entries()) {
		if (id === idToCheck) {
			ownTuples = tuples;
		} else {
			otherTuples.push(...tuples);
		}
	}

	for (const otherTuple of otherTuples) {
		for (const tuple of ownTuples) {
			if (JSON.stringify(otherTuple) === JSON.stringify(tuple)) {
				return true;
			}
		}
	}
	return false;
}
