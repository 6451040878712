import { DocumentReference } from '@angular/fire/compat/firestore';

import { addYears, differenceInDays, isAfter, isBefore, parse } from 'date-fns';

import { BookingsTarget } from './bookings-target';
import { Market } from './market';
import { Offering } from './offering';
import { RevenueType } from './revenue-type';

export const rsDateFormat = 'yyyyMMdd';

export interface RevenueStream {
	name: string;
	description?: string;
	markets?: DocumentReference<Market>[];
	offerings?: DocumentReference<Offering>[];
	order: number;
	bookingsTargets?: BookingsTargets;
	startDate: string; // yyyyMMdd
	endDate?: string; // yyyyMMdd
	crmOppValues?: { [id: string]: CrmOppValue }; // hard to update object arrays in Firestore
	isCatchAll?: boolean;
	useCrmForecast?: boolean;
}

export type BookingsTargets = { [year: number]: BookingsTarget };

export interface CrmOppValue {
	field: string;
	type: DocumentReference<RevenueType>;
	annualizationMultiplier?: number;
	isNegative: boolean;
}

type RevenueStreamWithDates = Omit<RevenueStream, 'startDate' | 'endDate'> & { startDate: Date; endDate: Date };

export function toRevenueStreamWithDates(stream: RevenueStream): RevenueStreamWithDates {
	const startDate = parse(stream.startDate, rsDateFormat, new Date());
	const endDate = stream.endDate ? parse(stream.endDate, rsDateFormat, new Date()) : null;
	return { ...stream, startDate, endDate };
}

export function sortRevenueStreams(a: RevenueStream, b: RevenueStream) {
	if (a.order != null && b.order != null) {
		return a.order - b.order;
	}
	return a.name.localeCompare(b.name);
}

export function isRevenueStreamActive(stream: RevenueStream, strategyStart: Date) {
	const { startDate, endDate } = toRevenueStreamWithDates(stream);
	return (
		isBefore(startDate, addYears(strategyStart, 1)) &&
		(!endDate || isAfter(endDate, strategyStart)) &&
		(!endDate || differenceInDays(endDate, startDate) > 0)
	);
}
