import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-select',
	template: `
		<mat-form-field [appearance]="appearance" [ngClass]="fieldClass">
			<mat-select [formControl]="control" [placeholder]="placeholder">
				<mat-option *ngFor="let option of options" [value]="option.key">
					{{ option.value }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<TValue> extends InputComponent<object, TValue, string> {
	@Input() placeholder = '';
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: unknown } | null | undefined;
	@Input() options: KeyValue<TValue, string>[];
	@Input() appearance: MatFormFieldAppearance = 'outline';

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'blur');
	}

	override unmask(x: unknown): TValue {
		return x as TValue;
	}
}
