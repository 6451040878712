<div
	class="kpi-box"
	[class.clickable]="link?.length > 0"
	[style.background-color]="lightColor"
	(click)="handleNavigation()"
>
	<div *ngIf="isInitiative" class="kpi-super-title">{{ TermKey.initiative | term | uppercase }}</div>
	<div class="kpi-title truncate w-100" title="{{ title }}">{{ title }}</div>
	<div class="percent-wrapper truncate w-100" title="{{ percentText + ' - ' + status }}">
		<span class="percent">{{ percentText }}</span> - {{ status }}
	</div>
	<div>
		<ayeq-kpi-progress-bar [value]="percent" [color]="darkColor"></ayeq-kpi-progress-bar>
	</div>
	<div class="subtext truncate w-100" title="{{ subText + (subsubText ? ' - ' + subsubText : '') }}">
		{{ subText }} <span *ngIf="subsubText">{{ subsubText }}</span>
	</div>
	<div class="kpi-annotation-wrapper" *ngIf="showAnnotations" [style.background-color]="darkColor">
		<div class="annotation" [title]="annotation">
			{{ annotation }}
		</div>
	</div>
</div>
