import { Component, Input, Pipe, PipeTransform } from '@angular/core';

import { WebStorage } from '@app/shared/core';
import { IssueRepository, IssueStatus, Path } from '@app/shared/data';
import { LocalStorageKeys } from '@app/shared/shared/constants';
import { faLongArrowUp } from '@fortawesome/pro-solid-svg-icons';

import { IssueWithUser } from '../issue-backlog.component';

@Pipe({ name: 'statusText' })
export class BacklogStatusTextPipe implements PipeTransform {
	transform(status: IssueStatus): string {
		switch (status) {
			case IssueStatus.assigned:
				return 'Assigned to Quarter';
			case IssueStatus.backlog:
				return 'Open Backlog';
			case IssueStatus.resolved:
				return 'Resolved';
			default:
				return '';
		}
	}
}

@Component({
	selector: 'ayeq-issue-backlog-item',
	templateUrl: './issue-backlog-item.component.html',
	styleUrls: ['./issue-backlog-item.component.scss'],
	providers: [BacklogStatusTextPipe],
})
export class IssueBacklogItemComponent {
	readonly canEdit = true;
	longArrowUp = faLongArrowUp;
	@Input() issue: IssueWithUser;
	IssueStatuses = [IssueStatus.assigned, IssueStatus.backlog, IssueStatus.resolved];

	constructor(private issueRepository: IssueRepository) {}

	async update() {
		await this.issueRepository.updateIssue(
			Path.issue(WebStorage.getItem(LocalStorageKeys.TenantId), this.issue.id),
			this.issue.resolution
		);
	}
}
