import { KeyValue } from '@angular/common';

export enum SegmentSource {
	Custom = 'CUSTOM',
	Opportunity = 'OPPORTUNITY',
	Account = 'ACCOUNT',
	Contact = 'CONTACT',
}

export type CrmFieldSource = Exclude<SegmentSource, SegmentSource.Custom>;
export const CrmFieldSource = {
	[SegmentSource.Opportunity]: SegmentSource.Opportunity,
	[SegmentSource.Account]: SegmentSource.Account,
	[SegmentSource.Contact]: SegmentSource.Contact,
} as const;

// TODO: does this need to be termed?
export const SEGMENT_SOURCE_DISPLAY: KeyValue<SegmentSource, string>[] = [
	{ key: SegmentSource.Custom, value: 'Custom' },
	{ key: SegmentSource.Opportunity, value: 'From CRM Opportunity' },
	{ key: SegmentSource.Account, value: 'From CRM Account' },
	{ key: SegmentSource.Contact, value: 'From CRM Contact' },
];
