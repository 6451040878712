import { Observable } from 'rxjs';

import { Role, Tenant } from '../models';
import { Market } from '../models/market';
import { Offering } from '../models/offering';
import { RevenueType } from '../models/revenue-type';
import { SegmentType } from '../models/segment';
import { FsCollection, Path } from '../utils/path';
import { ActionItemDAO } from './action-item.dao';
import { BaseDAO } from './base.dao';
import { IssueDAO } from './issue.dao';
import { RevenueStreamDAO } from './revenue-stream.dao';
import { SegmentDao } from './segment.dao';
import { StrategyDAO } from './strategy.dao';

export class TenantDAO extends BaseDAO<Tenant> {
	private _revenueStreams: Observable<RevenueStreamDAO[]>;
	private _segments: Observable<SegmentDao[]>;
	private _marketSegments: Observable<SegmentDao[]>;
	private _offeringSegments: Observable<SegmentDao[]>;
	private _marketingSegments: Observable<SegmentDao[]>;
	private _strategies: Observable<StrategyDAO[]>;
	private _markets: Observable<BaseDAO<Market>[]>;
	private _offerings: Observable<BaseDAO<Offering>[]>;
	private _issues: Observable<IssueDAO[]>;
	private _actionItems: Observable<ActionItemDAO[]>;
	private _revenueTypes: Observable<BaseDAO<RevenueType>[]>;
	private _roles: Observable<BaseDAO<Role>[]>;

	get revenueStreams(): Observable<RevenueStreamDAO[]> {
		if (this._revenueStreams == null) {
			const path = `${this.path}/${FsCollection.RevenueStreams}`;
			this._revenueStreams = this.initCollection(RevenueStreamDAO, path);
		}
		return this._revenueStreams;
	}

	get segments(): Observable<SegmentDao[]> {
		if (this._segments == null) {
			const path = `${this.path}/${FsCollection.Segments}`;
			this._segments = this.initCollection(SegmentDao, path, (ref) => ref.where('isActive', '==', true));
		}
		return this._segments;
	}

	get marketSegments(): Observable<SegmentDao[]> {
		if (this._marketSegments == null) {
			const path = `${this.path}/${FsCollection.Segments}`;
			this._marketSegments = this.initCollection(SegmentDao, path, (ref) =>
				ref.where('type', '==', SegmentType.TarketMarket).where('isActive', '==', true).orderBy('order')
			);
		}
		return this._marketSegments;
	}

	get offeringSegments(): Observable<SegmentDao[]> {
		if (this._offeringSegments == null) {
			const path = `${this.path}/${FsCollection.Segments}`;
			this._offeringSegments = this.initCollection(SegmentDao, path, (ref) =>
				ref.where('type', '==', SegmentType.Offering).where('isActive', '==', true).orderBy('order')
			);
		}
		return this._offeringSegments;
	}

	get marketingSegments(): Observable<SegmentDao[]> {
		if (this._marketingSegments == null) {
			const path = `${this.path}/${FsCollection.Segments}`;
			this._marketingSegments = this.initCollection(SegmentDao, path, (ref) =>
				ref.where('type', '==', SegmentType.Marketing).where('isActive', '==', true).orderBy('order')
			);
		}
		return this._marketingSegments;
	}

	get strategies(): Observable<StrategyDAO[]> {
		if (this._strategies == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.strategies(tenantId);
			this._strategies = this.initCollection(StrategyDAO, path);
		}
		return this._strategies;
	}

	get markets(): Observable<BaseDAO<Market>[]> {
		if (this._markets == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.markets(tenantId);
			this._markets = this.initCollection(BaseDAO<Market>, path);
		}
		return this._markets;
	}

	get offerings(): Observable<BaseDAO<Offering>[]> {
		if (this._offerings == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.offerings(tenantId);
			this._offerings = this.initCollection(BaseDAO<Offering>, path);
		}
		return this._offerings;
	}

	get issues(): Observable<IssueDAO[]> {
		if (this._issues == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.issues(tenantId);
			this._issues = this.initCollection(IssueDAO, path);
		}
		return this._issues;
	}

	get actionItems(): Observable<ActionItemDAO[]> {
		if (this._actionItems == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.actionItems(tenantId);
			this._actionItems = this.initCollection(ActionItemDAO, path);
		}
		return this._actionItems;
	}

	get revenueTypes(): Observable<BaseDAO<RevenueType>[]> {
		if (this._revenueTypes == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.revenueTypes(tenantId);
			this._revenueTypes = this.initCollection(BaseDAO<RevenueType>, path);
		}
		return this._revenueTypes;
	}

	get roles(): Observable<BaseDAO<Role>[]> {
		if (this._roles == null) {
			const { tenantId } = Path.getIds(this.path);
			const path = Path.tenantRoles(tenantId);
			this._roles = this.initCollection(BaseDAO<Role>, path);
		}
		return this._roles;
	}
}
