export interface SegmentValue {
	name: string;
	order: number;
	hidden?: boolean;
	isActive: boolean;
}

export interface CrmStringSegmentValue extends SegmentValue {
	sourceValue: string;
}

export interface CrmNumberSegmentValue extends SegmentValue {
	sourceMinValue: number;
	sourceMaxValue?: number;
}

export function numberSegmentValueName(
	formatFn: (v: number) => string,
	sourceMinValue: number,
	sourceMaxValue?: number
): string {
	return formatFn(sourceMinValue) + (sourceMaxValue ? ` - ${formatFn(sourceMaxValue)}` : '+');
}
