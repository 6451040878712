import { SegmentSource } from '../enums/crm-field-source.enum';

export type Segment = CustomSegment | CrmSegment;

interface BaseSegment {
	name: string;
	type: SegmentType;
	grouping?: SegmentGrouping;
	source: SegmentSource;
	order: number;
	isActive: boolean;
}

export interface CustomSegment extends BaseSegment {
	source: SegmentSource.Custom;
}

export interface CrmSegment extends BaseSegment {
	source: Exclude<SegmentSource, SegmentSource.Custom>;
	sourceLabel: string;
	sourceName: string;
	sourceType: SourceType;
}

export enum SegmentType {
	TarketMarket = 'TARGET_MARKET',
	Offering = 'OFFERING',
	Marketing = 'MARKETING',
}

export enum SegmentGrouping {
	Firmographics = 'Firmographics',
	Personas = 'Personas',
}

export type SourceType = 'enum' | 'number' | 'currency' | 'custom';

export function isCrmSegment(segment: Segment): segment is CrmSegment {
	return (segment as Partial<CrmSegment>).sourceName !== undefined;
}
