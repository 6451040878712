<div class="issue w-100">
	<div class="issue-row">
		<div class="title">
			<div class="text-truncate fw-bold" [title]="issue.name">{{ issue.name }}</div>
			<fa-icon
				*ngIf="issue.priority"
				class="ms-1 priority"
				[ngClass]="issue.priority.toLowerCase()"
				[icon]="longArrowUp"
			></fa-icon>
		</div>
		<mat-form-field class="flex-shrink-0 no-appearance" appearance="fill">
			<mat-select
				[(ngModel)]="issue.resolution"
				placeholder="Select Strategy"
				(ngModelChange)="canEdit ? update() : null"
				[disabled]="!canEdit"
			>
				<mat-option *ngFor="let status of IssueStatuses" [value]="status">
					{{ status | statusText }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="issue-row my-2">{{ issue.description }}</div>
	<div class="issue-row">
		<span class="text-truncate">{{ issue.usersName }}</span>
		<span class="flex-shrink-0">{{ issue.createdOn.toDate() | date }}</span>
	</div>
</div>
