import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { UpdateData } from '@angular/fire/firestore';

import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';

import { LogService } from '@app/shared/core';

import { HistoricalBookingsModel, StreamData } from '../models';
import { Path } from '../utils';

@Injectable()
export class StreamDataRepository {
	constructor(
		private afs: AngularFirestore,
		private log: LogService
	) {}

	public async removeProcessFromStreams(processPath: string) {
		const { tenantId, strategyId } = Path.getIds(processPath);
		const processDoc = this.afs.doc(processPath);
		const streams = await firstValueFrom(
			this.afs
				.collection<StreamData>(Path.streamDataCollection(tenantId, strategyId), (x) =>
					x.where('process', '==', processDoc.ref)
				)
				.get()
		);
		await Promise.all(streams.docs.map((s) => this.deleteProcessAndBookingsModel(s)));
	}

	private async deleteProcessAndBookingsModel(
		streamData: firebase.firestore.QueryDocumentSnapshot<StreamData>
	): Promise<void> {
		const update = { process: firebase.firestore.FieldValue.delete() } as StreamData;
		await this.deleteBookingsModel(streamData, update);
	}

	public async changeProcess(streamDataRef: DocumentReference<StreamData>, processId: string): Promise<void> {
		const streamData = await streamDataRef.get();
		if (!streamData.exists) {
			return;
		}
		const { tenantId, strategyId } = Path.getIds(streamDataRef.path);
		const processRef = this.afs.doc(Path.process(tenantId, strategyId, processId)).ref;

		// remove any bookings model associated with the old process
		await this.deleteBookingsModel(streamData, {
			process: processRef || firebase.firestore.FieldValue.delete(),
		} as StreamData);
	}

	public async deleteBookingsModel(
		streamDataDoc: firebase.firestore.DocumentSnapshot<StreamData>,
		initialUpdate?: StreamData
	): Promise<void> {
		const streamData = streamDataDoc.data();
		const update: UpdateData<StreamData> = initialUpdate ?? {};
		if (streamData.bookingsModel != null) {
			await this.addDeleteBookingsModelToUpdate(streamData, streamDataDoc.id, update, 'bookingsModel');
		}
		if (streamData.sandboxBookingsModel != null) {
			await this.addDeleteBookingsModelToUpdate(streamData, streamDataDoc.id, update, 'sandboxBookingsModel');
		}
		if (streamData.actualBookingsModel != null) {
			await this.addDeleteBookingsModelToUpdate(streamData, streamDataDoc.id, update, 'actualBookingsModel');
		}
		if (Object.keys(update).length) {
			await streamDataDoc.ref.update(update);
		}
	}

	private async addDeleteBookingsModelToUpdate(
		streamData: StreamData,
		streamId: string,
		update: UpdateData<StreamData>,
		bookingsModelKey: 'actualBookingsModel' | 'sandboxBookingsModel' | 'bookingsModel'
	) {
		const bookingsModelData = (await streamData[bookingsModelKey].get()).data();
		if (bookingsModelKey !== 'bookingsModel') {
			await streamData[bookingsModelKey].delete();
		} else {
			// eslint-disable-next-line no-param-reassign
			update.historicalBookingsModels = firebase.firestore.FieldValue.arrayUnion({
				archivedOn: firebase.firestore.Timestamp.now(),
				bookingsModel: streamData.bookingsModel,
				process: streamData.process,
			} as HistoricalBookingsModel);
		}
		// eslint-disable-next-line no-param-reassign
		update[bookingsModelKey] = firebase.firestore.FieldValue.delete();

		this.log.info(`${bookingsModelKey} deleted`, {
			revenueStream: streamId,
			[`${bookingsModelKey}Data`]: bookingsModelData,
		});
	}
}
