import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-autocomplete-input',
	template: `
		<mat-form-field appearance="outline" [ngClass]="fieldClass">
			<input
				#input
				[name]="name"
				matInput
				[formControl]="control"
				[placeholder]="placeholder"
				[matAutocomplete]="auto"
				(input)="filter()"
				(focus)="filter()"
			/>
			<mat-autocomplete
				requireSelection
				#auto="matAutocomplete"
				[displayWith]="displayFn.bind(this)"
				[panelWidth]="panelWidth"
			>
				@for (option of filteredOptions; track option.key) {
					<mat-option [value]="option.key">{{ option.value }}</mat-option>
				}
			</mat-autocomplete>
		</mat-form-field>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent<TValue> extends InputComponent<object, TValue, string> {
	@Input({ required: true }) set options(opts: KeyValue<TValue, string>[]) {
		if (opts) {
			this.allOptions = opts;
			this.filter();
			this.control.setValue(this.control.value);
		}
	}

	@Input() placeholder = '';
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: unknown } | null | undefined;
	// Passthrough to mat-autocomplete
	@Input() panelWidth: string | number;

	@ViewChild('input') input: ElementRef<HTMLInputElement>;

	private allOptions: KeyValue<TValue, string>[];
	filteredOptions: KeyValue<TValue, string>[];

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'change');
	}

	displayFn(value: TValue): string {
		return this.allOptions?.find((o) => o.key === value)?.value ?? '';
	}

	filter(): void {
		const value = this.input?.nativeElement.value.toLowerCase();
		this.filteredOptions = value
			? this.allOptions.filter((option) => option.value.toLowerCase().includes(value))
			: this.allOptions.slice();
	}

	override unmask(x: unknown): TValue {
		return x as TValue;
	}
}
