import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-checkbox-input',
	template: `
		<mat-checkbox [name]="name" color="primary" [formControl]="control">
			<span *ngIf="label">{{ label }}</span>
		</mat-checkbox>
		<ng-content select="[trailing]"></ng-content>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxInputComponent<TValue> extends InputComponent<object, TValue, string> {
	@Input() label: string;

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'change');
	}

	override unmask(x: unknown): TValue {
		return x as TValue;
	}
}
